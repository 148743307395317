// Importing Libs

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './components/dashboard/serviceWorker';

import {BrowserRouter as Router, Route} from 'react-router-dom';
import ScrollMemory from 'react-router-scroll-memory';

// Importing TailwindCSS
import "stylesheets/app"

// Context
import {Provider} from './components/brand_site/context.jsx';

// Importing App
import App from './components/brand_site/app.jsx';



// Rendering Router, Context, and App
ReactDOM.render(<Router>
    {/* <ScrollMemory elementID="c-page__inner" /> */}
    <Route render={ () => <Provider><App /></Provider> } />
</Router>, document.getElementById('root'));

serviceWorker.unregister();
